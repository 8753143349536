.body {
    overflow-x: hidden;
}

.careers-container_el8 {
    margin: 0 auto;
    max-width: 1279px;
    padding: 20px;
}

.section_el8 {
    margin-bottom: 30px;
}

.parsgh-heading_el8 {
    font-size: 40px;
    margin-bottom: 10px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
}

.parsgh-subheading_el8 {
    font-size: 22px;
    line-height: 1.6;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 400;
    text-align: center;
}

.parsgh_el8 {
    font-size: 22px;
    line-height: 1.6;
    font-family: wittgenstein;
    font-weight: 400;
}

.parsghclr_el8 {
    color: black;
}

.parsghclr1 {
    color: white;
}

.explore-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.explore-button:hover {
    background-color: #0056b3;
}

.show-more-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 22px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px;
}

.show-more-button:hover {
    background-color: #0056b3;
    color: #fff;
}

.benefits-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.careers-card {
    flex: 1 1 200px;
    padding: 20px;
    /* background: linear-gradient(to right, #4682B4, #3F51B5); */
    background: #89d6ed;;
    /* background-color: #87CEEB ; */
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    transition: opacity 0.3s, transform 0.3s;
    color: rgb(1, 1, 1);
}

.job-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px;
    list-style: disc;
    padding: 0;
    margin: 0;
}

.job-item {
    font-size: 22px;
    font-family: wittgenstein;
    font-weight: 400;
    margin-left: 20px;
    color: black;
    text-align: left;
}

.hide {
    display: none;
}

.dflexddrr {
    display: flex;
    flex-direction: column;
} 
  
.dflexddrrr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 22px;
    font-weight: 400;
    font-family: 'wittgenstein';
    flex-wrap: wrap;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.job-portals {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.portal-card {
    padding: 20px;
    background-color: #f0f0f0;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
    text-align: center;
    margin: 0 10px;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.portal-card:hover {
    transform: scale(1.05);
}

.portal-card:nth-child(1) {
    background-color: #0058a3; /* Naukri - Blue */
}

.portal-card:nth-child(2) {
    background-color: #0077b5; /* LinkedIn - Blue */
}

.portal-card:nth-child(3) {
    background-color: rgb(82, 3, 82); /* Foundit - Tomato */
}

.portal-logo {
    width: 95%;
    height: auto;
    transition: transform 0.3s, filter 0.3s;
    border-radius: 25%;
}

.portal-card:hover .portal-logo {
    transform: scale(1.2);
    filter: brightness(1.2);
}

/* Responsive styles */
@media (min-width: 2560px) and (max-width: 2560px) {
    .careers-container {
        padding: 20px;
        max-width: 2285px;
        margin: 0 auto;
    }

    .parsgh-subheading {
        font-size: 32px;
    }

    .parsgh {
        font-size: 32px;
    }

    .job-item {
        font-size: 32px;
    }
}

@media (max-width: 768px) {
    .parsgh-heading {
        font-size: 18px;
    }
    .parsgh_el8 {
        font-size: 18px;
        line-height: 1.6;
        font-family: wittgenstein;
        font-weight: 400;
    }
    .parsgh-heading_el8 {
        font-size: 30px;
        margin-bottom: 10px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        text-align: center;
        color: black;
    }
    .parsgh-subheading, .parsgh {
        font-size: 18px;
    }

    .explore-button, .show-more-button {
        padding: 8px 16px;
        font-size: 18px;
    }

    .job-list {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row on medium screens */
    }

    .job-item {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .parsgh-heading {
        font-size: 18px;
    }

    .parsgh-subheading, .parsgh {
        font-size: 18px;
    }

    .explore-button, .show-more-button {
        padding: 6px 12px;
        font-size: 18px;
    }

    .job-list {
        grid-template-columns: 1fr; /* 1 item per row on small screens */
    }

    .job-item {
        font-size: 18px;
    }
}
