.why-choose_el641 {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 2rem;
    overflow: hidden;
}
.card_ru {
  width: 300px;
  height: 246px;
  background-color: red;

}
.el6-hdimg2 {
    position: absolute;
    text-align: center;
    top: 55%;
    left: 20%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 60px;
    font-weight: 600;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .el6-btnpr2 {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 20.5%;
    width: 350px;
    font-size: 30px;
    transform: translate(-50%, -50%);
    color: white;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
.why-choose_el64:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}





.expertise-content p {
    font-size: 22px; /* Default font size for smaller screens */
    color: #555;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    padding: 1rem;
    font-family: "wittgenstein";
    border-radius: 6px;
    background: #f0f8ff;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.xpertise-content{
    font-size: 22px;
    font-family: "wittgenstein";
}

.expertise-content p:hover {
    background-color: #5ec4e6;
    color: #000000;
}

.expertise-content p strong {
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
}

.expertise-content p strong:hover {
    color: #1c4e04;
}
.clr-blue{
background-color: #a7cdf7;
}
/* Responsive Design */
@media (max-width: 786px) {


    .xpertise-content  {
        font-size: 18px; 
    }
}
@media (min-width: 320px) and (max-width: 430px) {
    .el6-hdimg2 {
      top: 29%;
      left: 30%;
      font-size: 24px;
      font-weight: 600;
    }
    .el6-btnpr2 {
      font-size: 18px;
      left: 29.5%;
      top: 57%;
      width: 160px;
    }
  }
  @media (min-width: 431px) and (max-width: 637px) {
    .el6-hdimg2 {
      top: 27%;
      left: 30%;
      font-size: 32px;
      font-weight: 600;
    }
    .el6-btnpr2 {
      font-size: 20px;
          left: 28.5%;
          top: 61%;
          width: 190px;
    }
  }
  @media (min-width: 638px) and (max-width: 790px) {
    .el6-hdimg2 {
      top: 43%;
      left: 24%;
      font-size: 36px;
      font-weight: 600;
    }
    .el6-btnpr2 {
      top: 61%;
      left: 24.5%;
      font-size: 30px;
      width: 250px;
    }
  }
  @media (min-width: 791px) and (max-width: 1024px) {
    .el6-hdimg2 {
      top: 47%;
      left: 24%;
      font-size: 39px;
      font-weight: 600;
    }
    .el6-btnpr2 {
      top: 57%;
      left: 23.5%;
      font-size: 30px;
      width: 260px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1200px) {
    .el6-hdimg2 {
      top: 47%;
      left: 24%;
      font-size: 39px;
      font-weight: 600;
    }
    .el6-btnpr2 {
      top: 57%;
      left: 23.5%;
      font-size: 30px;
      width: 260px;
    }
  }
  @media (min-width: 768px) and (max-width: 786px) {
    .el6-ruc1 {
      height: 214px;
    }
  }
  @media (min-width: 787px) and (max-width: 991px) {
    .el6-ruc1 {
      height: 278px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .el6-ruc1 {
      height: 342px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .el6-ruc1 {
      height: 245px;
    }
  }
 
  @media (min-width: 787px) and (max-width: 991px) {
    .el6-ruc12 {
      height: 344px;
    }
  }