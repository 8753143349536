/* General styles for scroll tracker */
.scroll-tracker {
    position: fixed;
    bottom: 96;
    right: 38px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
}

/* Hide scroll tracker on mobile devices */
@media (max-width: 600px) {
    .scroll-tracker {
      display: none;
      /* Hide scroll buttons on mobile devices */
    }
  }
  @media (min-width: 601px) {
    .scroll-tracker {
      display: flex;
      /* Ensure scroll buttons are visible */
    }
  }  
