
  .footer {
    background: url('./images/FooterImage.jpeg') no-repeat center center; /* LinkedIn image as background */
    background-size: cover;
    position: relative;
    color: #fff;
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 50px;
    z-index: 1;
  }
  
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Black overlay with transparency */
    z-index: -1;
  }
  .main-d{
    padding-left: 20px;
  }
  .f1 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 30px;
  }
  
  .f {
    text-align: left;
    line-height: 1;
  }
  
  .f2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-bottom: 40px;
    padding-top: 20px;
  }
  
  .logo {
    width: 15%;
  }
  
  .b1 {
    height: 50px;
    width: 140px;
    border-radius: 8px;
    background-color: #0a74da; 
    font-size: 19px;
    font-weight: bold; 
    color: #fff;
    border: none;
    margin-right: 100px;
    cursor: pointer; 
    transition: background-color 0.3s ease, transform 0.3s ease; 
  }

.b1:hover {
    background-color: #094a8f; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}
  
  .t1 {
    text-decoration: none;
  }
  
  .h2 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 40px;
    padding-left: 85px;
  }
  
  .c1 {
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .h5 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .h6 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 15px;
  }
  
  .p_el9 {
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .Linkd{
    text-decoration: none;
    color: white;
  }
  .endf_el9 {
    height: 60px;
    width: 100%;
    background: #262e37;
    /* background: #3005DE; */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
    position: absolute; /* Position at the bottom */
    left: 0;
  }
  @media (max-width: 768px) {
    .f1 {
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
    }
  
    .f2 {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .f {
      text-align: center;
      padding: 10px 0;
    }
  
    .b1 {
      justify-content: center;
      display: flex;
      align-items: center;
      margin: 0px;
    }
  .bmkk{
    justify-content: center;
      display: flex;
      align-items: center;
  }
    .h2 {
      font-size: 30px;
      padding-left: 0px;
    }
  
    .h5 {
      margin-top: 20px;
    }
  
    .logo {
      width: 30%;
      margin: 0 auto;
    }
  }