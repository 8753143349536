.bg{
    background-color: rgb(227, 227, 228);
  }
  
  .img {
    width: 100% ;
    height: auto;
    object-fit: cover;
}

.image{
    width: 100%;
}



.body {
    overflow-x: hidden;
  }
  .tdaj {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s;
  }
  
  .tdaj:hover {
    color: rgb(51, 185, 47);
  }
  
  .readtxtaj {
    font-weight: bold;
    font-size: 15px;
  }
.display{
    display: flex;
    align-items: center;
    justify-content: center;
    
     
}
.flex{
    flex-direction: row;
}

.txtst{
    position: absolute;
    top: 16%;
    color:white;
    font-size: 50px;
    font-weight: 600;
    padding-left: 10px;
}
.oimgaj {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Use full width of the container */
    border-radius: 10px;
}

.oimgaj img {
    width: 30%; /* Image width set to 30% of the container */
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    height: auto; /* Maintain aspect ratio */
    width: 450px;
}
h2{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.txtst h2 {
    font-size: 70px; /* Default size */
}

.txtst p {
    font-size: 50px; /* Default size */
}

/* .btn {
    font-size: 18px;
    color: white;
    padding: 16px 34px;
  border-radius: 8px;
border-color: white;
    background-color: transparent;
  } */
  .btn:hover{
    background-color: white;
    color: black;
  }

.cardst{
    height: 180px;
    width: 490px;
    background: #047cb4;
    box-sizing: border-box;
    border-radius: 10px ;
    color: white;
    margin: 20px 10px 0px;
   
  }
.cardst:hover{
    /* background-color: rgb(167, 84, 84); */
    /* background: #033147; */
    /* color: black;    */
}

.description:hover{
background-color: red;
}
.headingss{
    font-size: 35px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 10px;
}

.fontszocs{
        font-size: 22px;
        font-weight: 700;
        margin-top: 0px;
        font-family:wittgenstein;
        /* color: rgb(20, 18, 18); */
        text-align: center;
}

.parastella{
        font-size: 22px;
        font-weight: 400;
         padding-top: 40px;
         padding-left: 20px;
         padding-right: 20px;
        font-family:wittgenstein;
        text-align: center;
 
    

}


.cardimgsss{
    height: 50px;
    width: 70px;
    margin-left:10px;
    padding: 0px 5px 0px 0px ;
    display: flex;
    justify-content: center;
}

.gapss{
    gap: 5%;
}

.mars{
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    justify-content:center ;
}

.flexing{
    display: flex;
    flex-direction: row;
}

 .padgs-cloud{
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    text-align: center;
}

  .dirssocs{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    justify-content: center;
    height: 110px;
  }

  @media (min-width: 425px) and (max-width:767px)
  {

    
    .mars {
        padding-left: 0px; 
        padding-right: 0px;
    }

   
    .flex {
        flex-direction: column;
    }
    .fontszocs {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
    }
    .cardst {
        height: 144px;
        width: 282px;
        /* background-color:  #d63636e8; */
        box-sizing: border-box;
        border-radius: 10px;
        margin: 19px 10px 0px;
        padding-left: 7px;
        padding-right: 7px;
        /* margin-right: 78px; */
    }
    

    .parastella {
        font-size: 18px;
        font-weight: 400;
        padding-top: 24px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: wittgenstein;
    }

    
  }

  @media (min-width: 320px) and (max-width:375px)
{

    .fontszocs {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
    }

    .parastella {
        font-size: 18px;
        font-weight: 400;
        padding-top: 24px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: wittgenstein;
    }

    .cardst {
        height: 180px;
        width: 285px;
        /* background: linear-gradient(to right, #4073b3, #00C6FF); */
        box-sizing: border-box;
        border-radius: 10px;
        margin: 20px 10px 0px;
    }
    .display{
        display: flex;
        flex-direction: column;
    }
    
}

@media (min-width: 376px) and (max-width:424px)
{
    .fontszocs {
        font-size: 20px;
        font-weight: 600;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
    }

    .parastella {
        font-size: 18px;
        font-weight: 400;
        padding-top: 24px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: wittgenstein;
    }

    .cardst {
        height: 180px;
        width: 285px;
        /* background: linear-gradient(to right, #4073b3, #00C6FF); */
        box-sizing: border-box;
        border-radius: 10px;
        margin: 20px 10px 0px;
    }
    .display{
        display: flex;
        flex-direction: column;
    }


}



@media (min-width: 768px) and (max-width: 991px)
{
    /* .txtst {
        position: absolute;
        top: 17%;
        color: white;
        font-size: 29px;
        font-weight: 600;
       padding-left: 10px;
    } */
    .mars {
        padding-left: 0px; 
        padding-right: 0px;
    }
    .flex {
        flex-direction: row;
        padding-left: 10px;
    }

    /* .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 56.1%;
        display: flex;
        padding: 0px;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
    }
 */
    .fontszocs {
        font-size: 21px;
        font-weight: 700;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
    }

    .padgs-cloud {
        padding-left: 0px;
    }
    /* .cardst {
        height: 190px;
    } */
    /* .txtst p {
        font-size: 30px;
    }
    .txtst h2 {
        font-size: 30px;
    }
    .btn {
        font-size: 15px;
        color: white;
        padding: 10px 20px;
    
    } */
    .parastella {
        font-size: 20px;
        font-weight: 400;
        padding-top: 24px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: wittgenstein;
    }


}

@media (min-width: 992px) and (max-width: 1199px){
    .cardst {
        height: 144px;
    }
 
    .padgs-cloud {
        padding-left: 35px;
    }
    .txtst h2 {
        font-size: 50px;
    }
    
    .txtst p {
        font-size: 40px; 
    }
    
    .cardst {
        height: 144px;
        margin-left: 0px;
        margin-right: 35px;
    }

    .parastella {
        font-size: 18px;
        font-weight: 400;
        padding-top: 24px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: wittgenstein;
    }
    
}
@media (min-width: 1200px) {
    .txt2aj h2 {
        font-size: 50px;
    }
    
    .txt2aj p {
        font-size: 40px;
    }
    .cardst {
        height: 144px;
    }
    .fontszocs {
        font-size: 22px;
        font-weight: 700;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
        text-align: center;
    }
}
@media (min-width:1024px) and (max-width:1024px) {

    .txt2aj h2 {
        font-size: 50px;
    }
    
    .txt2aj p {
        font-size: 40px;
    }
    .cardst {
        height: 144px;
    }

    .oimgaj img {
        width: 30%;
        max-width: 100%;
        height: auto;
        width: 434px;
    }

    .fontszocs {
        font-size: 22px;
        font-weight: 700;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: rgb(20, 18, 18); */
        text-align: center;
    }
}

@media (min-width:1440px) and (max-width:1440px) {
    .oimgaj img {
        width: 30%;
        max-width: 100%;
        height:auto ;
        width: 500px;
    }
    /* .padgs-cloud {
        padding-left: 0px;
    } */
}
@media (min-width:1641px) and (max-width:2559px){
.cardst {
    height: 180px;
    width: 565px;
    /* background: linear-gradient(to right, #4073b3, #00C6FF); */
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 10px 0px;
    margin-left: 398px;
    margin-right: -367px;
}
.fontszocs {
    font-size: 22px;
    font-weight: 700;
    margin-top: 0px;
    font-family: wittgenstein;
    /* color: white; */
}




}

@media (min-width:2560px) {
    .cardst {
        height: 180px;
        width: 565px;
        /* background: linear-gradient(to right, #4073b3, #00C6FF); */
        box-sizing: border-box;
        border-radius: 10px;
     
    }
    .fontszocs {
        font-size: 22px;
        font-weight: 700;
        margin-top: 0px;
        font-family: wittgenstein;
        /* color: white; */
    }}