/* Base Styles (no changes here) */
.img_el4 {
  width: 100%;
  /* height: auto; */
  position: relative;
}
.image_el4 {
  position: absolute;
  left: 30%; /* Adjust to control the overlap amount */
  top: 43%;
  transform: translateY(-50%);
  width: 21%; /* Adjust width as needed */
  height: auto;
  z-index: 1; /* Ensure the image is on top */
}
.image-container {
  width: 100%;
}
.cardimgtext_el4 {
  padding-left: 9%;
  border: 1px solid #ddd;
  border-radius: 8px;
  /* background-color: #ACC0FE; */
  background: #00c1fb;
  z-index: 0;
  max-width: 620px;
  text-align: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  color: white;
  font-size: 22px;
  font-family: "wittgenstein";
  margin-left: 165px;
  /* transition: background-color 0.3s ease, color 0.3s ease; */
}

.cardimgtext2_el4 {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #92d0ec;
  z-index: 0;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  color: rgb(2, 2, 2);
  font-size: 22px;
  font-family: "wittgenstein";
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8; /* Initial opacity */
  transform: translateY(0); /* Initial position */
}
.cardimgtext2_el4:hover {
  transform: translateY(-10px); /* Move the card up slightly */
  opacity: 1; /* Fully visible on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}
.cardimgtext2_el4 p {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.cardimgtext2_el4:hover p {
  transform: translateY(5px); /* Adjust the text position on hover */
  opacity: 1; /* Make the text fully visible */
}
.cardimgtext3_el4 {
 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.txsz_el4 {
  font-size: 90px;
  position: absolute;
  top: 50%;
  font-weight: 600;
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.dflex_el4 {
  display: flex;
  text-align: center;
  justify-content: center;
}

.sz_el4 {
  font-size: 40px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 600px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.sz1_el4 {
  font-size: 26px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */

}
.psz_el4 {
  font-family: "wittgenstein";
  font-size: 22px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 10px;
}

.midimg_el4 {
  width: 40%;
  height: auto;
  border-radius: 10px;
}

.card_el4 {
  width: 25%;
  height: 200px;
  background-color: blue;
}

.textpsz_el4 {
  font-family: "wittgenstein";
  font-size: 22px;
  padding-left: 70px;
  padding-right: 50px;
  padding-top: 80px;
  color: white;
}

.lastimg_el4 {
  width: 20%;
  height: 100px;
  padding-top: 50px;
}

/* .overlappig_el4{
    position: absolute;
    left: 400px;
    bottom: -1875px; 
    transform: translateY(-50%);
    width: 200px;
    height: 200px;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
    */

.button_el4 {
  width: auto;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 10px;
  color: black;
  font-size: 18px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.txtlt_el4 {
  padding-right: 20px;
}

/* Media Queries */

/* For mobile viewports (320px) */
@media (min-width: 320px) and (max-width: 374px) {
  .txsz_el4 {
    font-size: 40px;
    top: 40%;
  }
  .psz_el4 {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .sz_el4 {
    font-size: 30px;
  }
  .midimg_el4 {
    width: 80%;
  }
  .lastimg_el4 {
    width: 70%;
  }
  .card_el4 {
    width: 70%;
  }
  .textpsz_el4 {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .button_el4 {
    width: auto;
    margin-top: -6px;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 10px;
    color: black;
    font-size: 18px;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .image_el4 {
    position: absolute;
    left: 16%;
    top: 6%;
    transform: translateY(-50%);
    width: 67%;
    height: auto;
    z-index: 1;
  }
  .cardimgtext_el4 {
    margin-left: 4%;
    padding: 10px;
    max-width: 100%;
    margin-top: 38%;
    font-size: 17px;
    margin-right: 4%;
    
  }
  .cardimgtext1_el4 {
    margin-top: 0px;
    margin-bottom: 1rem;
  }
  .cardimgtext3_el4 {
    /* height: Auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
    margin-right: 10px;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}
.sz1_el4 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */
}
}

/* For mobile viewports (375px) */
@media (min-width: 375px) and (max-width: 424px) {
  .txsz_el4 {
    font-size: 45px;
    top: 15%;
  }
  .psz_el4 {
    font-size: 16px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .sz_el4 {
    font-size: 30px;
  }
  .midimg_el4 {
    width: 75%;
  }
  .lastimg_el4 {
    width: 65%;
  }
  .card_el4 {
    width: 60%;
  }
  .textpsz_el4 {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  /* .button_el4 {
        margin-top: 246px;
        width: 130px;
    } */
  .image_el4 {
    position: absolute;
    left: 17%;
    top: 8%;
    transform: translateY(-50%);
    width: 64%;
    height: auto;
    z-index: 1;
  }
  .cardimgtext_el4 {
    margin-left: 4%;
    padding: 10px;
    max-width: 100%;
    margin-top: 38%;
    font-size: 20px;
    margin-right: 4%;
  }
  .cardimgtext1_el4 {
    margin-top: 0px;
    margin-bottom: 1rem;
  }
  .cardimgtext3_el4 {
    /* height: Auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
    margin-right: 10px;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}
.sz1_el4 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */
}
}

/* For mobile viewports (425px) */
@media (min-width: 425px) and (max-width: 767px) {
  .txsz_el4 {
    font-size: 50px;
    top: 20%;
  }
  .psz_el4 {
    font-size: 18px;
    padding-left: 60px;
    padding-right: 60px;
  }
  .sz_el4 {
    font-size: 24px;
  }
  .midimg_el4 {
    width: 70%;
  }
  .lastimg_el4 {
    width: 60%;
  }
  .card_el4 {
    width: 35%;
    height: 350px;
  }
  .textpsz_el4 {
    font-size: 20px;
    padding-left: 60px;
    padding-right: 60px;
  }
  /* .button_el4 {
        margin-top: 390px;
        width: 140px;
    } */
  .image_el4 {
    position: absolute;
    left: 18%;
    top: 9%;
    transform: translateY(-50%);
    width: 62%;
    height: auto;
    z-index: 1;
  }
  .cardimgtext_el4 {
    margin-left: 4%;
    padding: 10px;
    max-width: 100%;
    margin-top: 38%;
    font-size: 20px;
    margin-right: 4%;
  }
  .cardimgtext1_el4 {
    margin-top: 25px;
    margin-bottom: 1rem;
  }
  .cardimgtext3_el4 {
    /* height: Auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
    margin-right: 10px;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}
.sz1_el4 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */
}
}

/* For tablet viewports (768px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .txsz_el4 {
    font-size: 70px;
    top: 40%;
  }
  .psz_el4 {
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .sz_el4 {
    font-size: 30px;
  }
  .midimg_el4 {
    width: 60%;
  }
  .lastimg_el4 {
    width: 40%;
    height: 200px;
  }
  .card_el4 {
    width: 70%;
  }
  .textpsz_el4 {
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  /* .button_el4 {
        margin-top: 210px;
        width: 140px;
    } */
  .overlappig_el4 {
    left: 25px;
    bottom: -1554px;
    transform: translateY(-50%);
    width: 200px;
    height: 200px;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .image_el4 {
    position: absolute;
    left: 1%;
    top: 34%;
    transform: translateY(-50%);
    width: 36%;
    height: auto;
    z-index: 1;
  }
  .cardimgtext_el4 {
    margin-left: 32%;
    padding: 15px;
    max-width: 68%;
    margin-top: -4%;
    margin-right: 4%;
  }
  .cardimgtext1_el4 {
    margin-top: 19px;
    margin-bottom: -1rem;
    margin-left: 34px;
  }
  .cardimgtext3_el4 {
    /* height: Auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 77px;
    margin-right: 50px;
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}
.sz1_el4 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */
}
}

/* For laptop/tablet viewports (1024px) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .txsz_el4 {
    font-size: 80px;
    top: 45%;
  }
  .psz_el4 {
    font-size: 22px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .sz_el4 {
    font-size: 36px;
  }
  .midimg_el4 {
    width: 50%;
  }
  .lastimg_el4 {
    width: 30%;
    height: 220px;
  }
  .card_el4 {
    width: 60%;
  }
  .textpsz_el4 {
    font-size: 22px;
    padding-left: 40px;
    padding-right: 40px;
  }
  /* .button_el4 {
        margin-top: 60px;
        width: 150px;
    } */
    .image_el4 {
        position: absolute;
        left: 10%;
        top: 44%;
        transform: translateY(-50%);
        width: 29%;
        height: auto;
        z-index: 1;
    }
  .cardimgtext_el4 {
    padding-left: 9%;
        font-size: 22px;
        font-family: 'wittgenstein';
        margin-left: 231px;
    }
  
  .cardimgtext1_el4 {
    margin-top: 25px;
    margin-bottom: 1rem;
  }
  .cardimgtext3_el4 {
    /* height: Auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 202px;
    /* margin-right: 50px; */
    /* transition: background-color 0.3s ease, color 0.3s ease; */
}
.sz1_el4 {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 700px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* color: black; */
  
}
.cardimgtext2_el4 {
  /* padding-left: 9%; */
  border: 1px solid #ddd;
  border-radius: 8px;
  /* background-color: #ACC0FE; */
  background: #a5bec5;
  z-index: 0;
  max-width: 100%;
  /* text-align: center; */
  /* height: Auto; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  margin-top: 50px;
  color: white;
  font-size: 22px;
  font-family: "wittgenstein";
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: -79px;
}
}
@media (min-width:1200px) and (max-width:1440px)
{
.image_el4 {
  position: absolute;
  left: 20%;
  top: 44%;
  transform: translateY(-50%);
  width: 23%;
  height: auto;
  z-index: 1;
   }
.cardimgtext_el4 {
  padding-left: 9%;
  border: 1px solid #ddd;
  border-radius: 8px;
  /* background-color: #ACC0FE; */
  background: #00c1fb;
  z-index: 0;
  max-width: 635px;
  /* text-align: center; */
  /* margin-left: 20px; */
 
  
  margin-bottom: 50px;
  
  margin-left: 214px;
}
}
@media  (min-width:1441px) and (max-width:2559px)
{
  .image_el4 {
    position: absolute;
    left: 21%;
    top: 43%;
    transform: translateY(-50%);
    width: 21%;
    height: auto;
    z-index: 1;
}
}

@media  (min-width:2560px)
 {
  .image_el4 {
      position: absolute;
      left: 34%;
      top: 43%;
      transform: translateY(-50%);
      width: 13%;
      height: auto;
      z-index: 1;
      
  }
  .cardimgtext_el4 {
    padding-left: 9%;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* background-color: #ACC0FE; */
    background: #00c1fb;
    z-index: 0;
    max-width: 620px;
    /* text-align: center; */
    height: 300px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    margin-bottom: 50px;
    color: white;
    font-size: 22px;
    font-family: "wittgenstein";
    margin-left: 49px;
}
}