/* Basic Reset */
.why-choose_el64 {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 2rem;
    overflow: hidden;
}

.why-choose_el64:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.h2b_el64 {
   
    color: #333;
    margin-bottom: 1rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    transition: color 0.3s ease;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}

.h2b_el64:hover {
    color: #007bff;
}

.expertise-content p {
    font-size: 22px; /* Default font size for smaller screens */
    color: #555;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    padding: 1rem;
    font-family: "wittgenstein";
    border-radius: 6px;
    background: #f0f8ff;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.expertise-content p:hover {
    background-color: #5ec4e6;
    color: #000000;
}

.expertise-content p strong {
    color: #333;
    font-weight: bold;
    transition: color 0.3s ease;
}

.expertise-content p strong:hover {
    color: #1c4e04;
}

/* Responsive Design */
@media (max-width: 786px) {
    .h2b_el64 {
        font-size: 18px; /* Adjust font size for smaller screens */
    }

    .expertise-content p {
        font-size: 18px; /* Adjust font size for smaller screens */
        padding: 0.75rem;
    }
}
